<template>
    <div >
        <div id="printdiv" v-if="tbldata.length >0"  style="display:none;margin:0 auto;float:top;margin-top:1px;width:100%">
            <printview v-bind:cerrecorddata="tbldata"/>
        </div>
        <div style="text-align: left;">
            <h2>查询合格证台账记录</h2>
        </div>
        <Form ref="queryForm" :model="queryFormInline" label-position="right" :label-width="100" style="margin-bottom:20px;border:1px solid lightgray;background:lightblue;padding: 5px 10px;">
            <table width="100%">
                <tr>
                    <td align="right" width="23%">
                        <i-select v-model="queryFormInline.parentid">
                            <i-option value="" >全部车型</i-option>
                            <i-option v-if="modellist !=null" v-for="(item,index) in modellist" :value="item.id" :key="item.id">{{item.modelno}}</i-option>
                        </i-select>
                    </td>
                    <td align="right" width="23%">
                        <FormItem  label="申请日期范围:" style="margin-bottom:5px;margin-top:10px">
                            <DatePicker type="daterange" format="yyyy-MM-dd" placeholder="选择日期范围" v-model="queryFormInline.requestdate"  @on-change="queryFormInline.requestdate=$event" @on-clear="queryFormInline.requestdate=null"></DatePicker>
                        </FormItem>
                    </td>
                    <td align="left">
                        <div style="display: inline-flex;justify-items: center;padding-left: 20px;">
                            <Button type="primary" @click="handleQuery()" >查询</Button>    
                            <Button  @click="handleReset()" style="margin-left:10px">重置</Button>
                        </div>
                    </td>
                </tr>
            </table>
        </Form>
        <!-- 文件列表-->
        <div style="margin: 5 auto;float: left;">
            <Button type="warning"  @click="previewdata">打印预览</Button>
            <Button type="primary"  @click="printdata" style="margin-left: 15px;">打印本页数据</Button>
            <Button type="success"  @click="exportdata" style="margin-left: 15px;">导出excel</Button>
        </div>
        <div style="padding-top:10px;clear: both;">
            <Table :columns="tblcolumns" :data="tbldata">
                <template  slot-scope="{ row, index }" slot="requestdate">
                    <div>
                        {{getpreDate(row.madedate)}}
                    </div>
                </template>
                <template  slot-scope="{ row, index }" slot="madedate">
                    <div>
                        {{formatDate(row.madedate)}}
                    </div>
                </template>
                <template  slot-scope="{ row, index }" slot="printdate">
                    <div>
                        {{formatDate(row.printdate)}}
                    </div>
                </template>
            </Table>
        </div>
        <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:5px;float:right"  @on-change="onpagechange"/>
    </div>
</template>
<script>
import * as sysTool from '@/zsjs/systemTool.js'
import CerRecordPrint from '@/views/business/workshop/checkdept/special/CerRecordPrint'
import {getPrinters,public_print,public_print_with_dir,public_preview_with_dir} from '@/zsjs/zskjprinter.js'
export default {
    name:'ziliao',
    data () {
            return {
                modellist:this._self.$root.$store.state.ModelListInfo,
                queryFormInline:{
                    companyid: this._self.$root.$store.state.LogonInfo.companyid,
                    parentid:null,
                    requestdate:null
                },
                addFormInline:{
                    companyid: this._self.$root.$store.state.LogonInfo.companyid,
                    parentid:null,
                    modelno:null,
                },
                tblpageinfo:{
                    total:0,
                    currentpage:1,
                    pagesize:20
                },
                tblcolumns: [
                    {
                        type: 'index',
                        width: 60,
                        align: 'center'
                    },
                    {
                        title: '申请日期',
                        slot: 'requestdate',
                    },      
                    {
                        title: '产品型号',
                        key: 'modelno',
                    },          
                    {
                        title: '整车编码',
                        key: 'vehicleno',
                    },   
                    {
                        title: '电机编码',
                        key: 'motorno',
                    },   
                    {
                        title: '合格证编码',
                        key: 'certificateno',
                    },
                    {
                        title: '生产日期',
                        slot: 'madedate',
                    },
                    {
                        title: '颜色',
                        key: 'vehiclecolor',
                    },
                    {
                        title: '打印时间',
                        slot: 'printdate',
                    },
                    {
                        title: '备注',
                        key: 'note',
                    }
                ],
                tbldata: []
            }
        },
        mounted (){
            console.log(this.modellist)

            this.queryFormInline.requestdate = [new Date(this.getstartdate()),new Date()]
            //this.handleQuery()
        },
        methods: {
            handleQuery() {
                let postdata = {
                    companyid: this.queryFormInline.companyid,
                    pageNum: this.tblpageinfo.currentpage,
                    pageSize: this.tblpageinfo.pagesize
                }
                console.log(this.queryFormInline)
                if(this.queryFormInline.requestdate !=null){
                    if(this.queryFormInline.requestdate[0] !=''){
                        postdata.madedatestart =sysTool.getNextDate(this.queryFormInline.requestdate[0],-1) 
                    }
                    if(this.queryFormInline.requestdate[1] !=''){
                        postdata.madedateend = sysTool.getNextDate(this.queryFormInline.requestdate[1],-1) 
                    }
                }
                
                if(this.queryFormInline.parentid !=null && this.queryFormInline.requestdate.parentid !=''){
                    postdata.modelno = this.getmodelnobyid(this.queryFormInline.parentid)
                }
                else{
                    this.$Message.error({content:'请先选择车型',duration:3})
                    return
                }

                for(var p in postdata){
                    if(postdata[p] == null || postdata[p] == ''){
                        delete postdata[p]
                    }
                }

                console.log(postdata)
                this.$axios({
                    method:'post',
                    url:"/api/vehicle/query",
                    data:postdata,
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data.data
                        console.log(res.data.data)
                        if(rspdata == null){
                            this.tbldata=[]
                        }
                        else{
                            this.tblpageinfo.total = res.data.data.totalnumber
                            this.tbldata =rspdata
                        }
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            onpagechange(num){
                this.tblpageinfo.currentpage = num
                this.handleQuery()
            },
            view(index){
                let openurl = window.location.protocol+"//"+window.location.host+this.tbldata[index].filepath
                window.open(openurl, '_blank');
            },
            previewdata(){
                public_preview_with_dir("printdiv",1,2);
            },
            printdata(){
                public_print_with_dir("printdiv",1,2);
            },
            exportdata(){
                let postdata = {
                    companyid: this.queryFormInline.companyid,
                }
                console.log(this.queryFormInline)
                if(this.queryFormInline.requestdate !=null){
                    if(this.queryFormInline.requestdate[0] !=''){
                        postdata.madedatestart =sysTool.getNextDate(this.queryFormInline.requestdate[0],-1) 
                    }
                    if(this.queryFormInline.requestdate[1] !=''){
                        postdata.madedateend = sysTool.getNextDate(this.queryFormInline.requestdate[1],-1) 
                    }
                }
                
                if(this.queryFormInline.parentid !=null && this.queryFormInline.requestdate.parentid !=''){
                    postdata.modelno = this.getmodelnobyid(this.queryFormInline.parentid)
                }
                else{
                    this.$Message.error({content:'请先选择车型',duration:3})
                    return
                }

                for(var p in postdata){
                    if(postdata[p] == null || postdata[p] == ''){
                        delete postdata[p]
                    }
                }

                console.log(postdata)
                this.$axios({
                    method:'post',
                    url:"/api/vehicle/exportyzdata",
                    data:postdata,
                    responseType: 'blob',
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    const link = document.createElement('a')
                    let blob = new Blob([res.data],{type: 'application/vnd.ms-excel'});
                    link.style.display = 'none'
                    link.href = URL.createObjectURL(blob);
                    let num = ''
                    for(let i=0;i < 10;i++){
                        num += Math.ceil(Math.random() * 10)
                    }

                    link.setAttribute('download', '车辆合格证台账记录.xlsx')
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                }.bind(this));   
            },
            getmodelnobyid(id){
                for(let i=0;i<this.modellist.length;i++){
                    if(this.modellist[i].id == id){
                        return this.modellist[i].modelno
                    }
                }
                return null
            },
            getpreDate(maodate){
                return sysTool.getNextDate(maodate,-1)
            },
            formatDate(date){
                if(date !=null)
                    return  (new Date(date)).Format('yyyy-MM-dd')
                else
                    return ''    
            },
            getstartdate(){
                return new Date().getFullYear()+'-01-01'
            }
        },
        components:{
            printview: CerRecordPrint
        }
}
</script>

<style scoped>
    .tblitemtitle {
        cursor: pointer;
    }
</style>